import React, { useState } from "react";
import logo from "../../../logo-duo.png";
import ChatModal from "../../../Components/ChatModal";
import { Link } from "react-router-dom";
const ChatbotLandingPage = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
          <div className="overflow-y-auto scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1]
 scrollbar-thin px-2">
      <ChatModal
        handleShow={handleShow}
        handleClose={handleClose}
        show={show}
      />


        <div className="d-flex justify-content-between align-items-center w-full py-2 px-8 border-bottom border-lightgrey ">
          <div className="d-flex align-items-center gap-3">
            <a href="/">
              <img
                src={logo}
                alt="Logo"
                style={{
                  width: "120px",
                  height: "40px",
                  padding: "5px",
                  borderRadius: "4px",
                }}
              />
            </a>
            <div className="overflow-hidden">
              <p className="custom-bg-bage badge text-custom px-2 py-1 ">Free</p>
            </div>
          </div>
          <div className="d-flex gap-2 cursor-pointer">
            <div className="d-none d-sm-flex"></div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "calc(90vh - 60px)" }}
        >
          <div className=" py-4 d-flex flex-colmn  justify-content-center">
            <div className="d-flex flex-column gap-3 position-relative">
              <div className="d-flex align-items-center gap-3">
                <div id="diagram"></div>
              </div>
              <div className="bg-light rounded p-3 d-flex flex-column flex-sm-row justify-content-between align-items-start text-center text-sm-start">
                <p className="text-dark fw-bold fs-4 mb-3 mb-sm-0">
                  Upload Your Document to Get Started
                </p>

                <button
                  onClick={handleShow}
                  className="btn btn-dark gap-3 w-100 w-sm-auto"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.4142 13.6095C13.0391 13.8595 12.6667 14 12.3333 14H3.66667C3.33333 14 3.03913 13.8595 2.58579 13.6095C2.14057 13.3594 2 13.0203 2 12.6667V10"
                      stroke="white"
                      strokeWidth="1.33"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M11.3332 5.33333L7.99984 2L4.6665 5.33333"
                      stroke="white"
                      strokeWidth="1.33"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M8 2V10"
                      stroke="white"
                      strokeWidth="1.33"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  Upload
                </button>
              </div>
              <div>
                <div className="d-flex gap-3 align-items-center mb-3 justify-content-between">
                  <p className="fs-4 fw-medium">
                    Or pick from one of the use cases below
                  </p>
                </div>
                <div className="flex flex-wrap justify-evenly gap-3">
                  <div
                    className="border rounded p-3 d-flex flex-column justify-content-between"
                    style={{ minWidth: "190px" }}
                  >
                    <div className="d-flex justify-content-between">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 12H12.01"
                          stroke="#8d8a95"
                          strokeWidth="1.33"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M16 6V4C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4V6"
                          stroke="#8d8a95"
                          strokeWidth="1.33"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M22 13C19.0328 14.959 15.5555 16.0033 12 16.0033C8.44445 16.0033 4.96721 14.959 2 13"
                          stroke="#8d8a95"
                          strokeWidth="1.33"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M20 6H4C2.89543 6 2 6.89543 2 8V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V8C22 6.89543 21.1046 6 20 6Z"
                          stroke="#8d8a95"
                          strokeWidth="1.33"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <Link to="/user/plans">
                      <span className="badge bg-primary text-white">Upgrade</span>
                      </Link>
                    </div>
                    <h5 className="opacity-50 mt-2 mb-1 fw-bold">
                      Analyze Reports
                    </h5>
                    <p className="opacity-50">
                      Analyze key data, extract vital info, & AI-ction!
                    </p>
                  </div>
                  <div
                    className="border rounded p-3 d-flex flex-column justify-content-between"
                    style={{ minWidth: "190px" }}
                  >
                    <div className="d-flex justify-content-between">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M7 21H17M12 3V21M3 7H5C7 7 10 6 12 5C14 6 17 7 19 7H21M16 16L19 8L22 3"
                          stroke="#8d8a95"
                          strokeWidth="1.33"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <Link to="/user/plans">
                      <span className="badge bg-primary text-white">Upgrade</span>
                      </Link>
                    </div>
                    <h5 className="opacity-50 mt-2 mb-1 fw-bold">
                      Generate Insights
                    </h5>
                    <p className="opacity-50">
                      Get actionable insights & improve strategies!
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ChatbotLandingPage;
