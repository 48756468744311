
import React, { createContext, useEffect, useState } from 'react';
import Helpers from '../Config/Helpers';
import axios from 'axios';

const ChatAppContext = createContext();
const ChatAppProvider = ({ children }) => {
    const [chatFile, setChatFile] = useState(null);
    const [newChat, setNewChat] = useState(null);
    const [chatid, setChatid] = useState(null);

    const [promptState, setPromptState] = useState(null);
    const [selectedPrompt, setSelectedPrompt] = useState(null);
    const [documentList, setDocumentList] = useState([]);



    return (
        <ChatAppContext.Provider value={{
            chatFile, chatid, setChatid, setChatFile,
            setPromptState, promptState, setSelectedPrompt,
            documentList, setDocumentList, selectedPrompt,
            newChat, setNewChat
        }}>
            {children}
        </ChatAppContext.Provider>
    );
};
export { ChatAppContext, ChatAppProvider };