import { useEffect, useRef, useState, useContext } from "react";
import { PromptTemplate } from "@langchain/core/prompts";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { StringOutputParser } from "@langchain/core/output_parsers";
import { ChatOpenAI } from "@langchain/openai";
import ChatGPTFormatter from "../../../Components/ChatgptFormatter";
import { combineDocs } from "../../../Components/combineDocs";
import PageLoader from "../../../Components/Loader/PageLoader";
import { useRetriever } from "../../../Components/retriever"; // Use retriever hook
import Helpers from "../../../Config/Helpers";
import { ChatAppContext } from "../../../Context/AppContext";
import MarkdownIt from "markdown-it";
import { jsPDF } from "jspdf";
import axios from "axios";
import { RunnableSequence } from "@langchain/core/runnables";
import { usePlan } from "../../../Context/PlanContext";
import { useApi } from "../../../Context/ApiContext"; // Import the API context

const md = new MarkdownIt();

const Chatbot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef();
  const { openAiApiKey } = useApi(); // Get OpenAI API key from context
  const retriever = useRetriever(); // Initialize retriever using the hook

  const { isPlanActive, userData, TokensAvailable, getProfileInfo } = usePlan();
  const [pageLoading, setPageLoading] = useState(false);
  const [chat, setChat] = useState({});
  const [isUserDataLoading, setIsUserDataLoading] = useState(true); // Track loading state for userData
  const [isSuggestionClicked, setIsSuggestionClicked] = useState(false);  // New state to track suggestion clicks
  const [isDone, setIsDone] = useState(true);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [isFirstResponse, setIsFirstResponse] = useState(false);

  const [noPermission, setNoPermission] = useState(false);
  const { chatFile, setChatFile } = useContext(ChatAppContext);
  const [isWriting, setisWriting] = useState(false);
  const { chat_id } = useParams();
  const chatContainer = useRef(null);

  const scrollToBottom = () => {
    if (chatContainer.current) {
      chatContainer.current.scrollTo({
        top: chatContainer.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const isHistory = location.state ? location.state.isHistory : null;

  const downloadSingleMessagePDF = (message) => {
    try {
      const pdf = new jsPDF();
      const margins = { top: 10, bottom: 10, left: 10, right: 10 };
      const pageHeight = pdf.internal.pageSize.height;
      const lineHeight = 10;
      let cursorY = margins.top;

      const splitText = pdf.splitTextToSize(
        message,
        pdf.internal.pageSize.width - margins.left - margins.right
      );

      splitText.forEach((line) => {
        if (cursorY + lineHeight > pageHeight - margins.bottom) {
          pdf.addPage();
          cursorY = margins.top;
        }
        pdf.text(margins.left, cursorY, line);
        cursorY += lineHeight;
      });

      pdf.save("download.pdf");
    } catch (error) {
      Helpers.toast("error", "Failed to download PDF.");
      console.error("Error downloading PDF:", error);
    }
  };

  const template = (id) => {
    navigate(`/user/templates-library/${Helpers.encryptString(id)}`);
  };

  const handleSuggestionClick = (suggestion) => {
    setUserInput(suggestion);
    setIsSuggestionClicked(true);
  };

  useEffect(() => {
    if (isSuggestionClicked && userInput) {
      console.log("User Input after suggestion click:", userInput);

      setTimeout(() => {
        getResponse(userInput);
      }, 500);

      setIsSuggestionClicked(false);
    }
  }, [userInput, isSuggestionClicked]);

  const handleDownload = async (msg) => {
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}user/generateDocx`,
        { msg: msg },
        Helpers.authHeaders
      );
      const downloadElement = document.createElement("a");
      downloadElement.href = `${Helpers.basePath}/${response.data.filePath}`;
      downloadElement.download = "generated_doc.docx";
      downloadElement.target = "_blank";
      document.body.appendChild(downloadElement);
      downloadElement.click();
      delfile(response.data.filePath);
      document.body.removeChild(downloadElement);
    } catch (error) {
      Helpers.toast("error", "Failed to generate DOCX.");
      console.error("Error generating DOCX:", error);
    }
  };

  const delfile = async (chatFile) => {
    try {
      await axios.post(
        `${Helpers.apiUrl}user/deletefile`,
        { file: chatFile },
        Helpers.authHeaders
      );
    } catch (error) {
      Helpers.toast("error", "Failed to delete file.");
      console.error("Error deleting file:", error);
    }
  };

  const getChat = async () => {
    if (chat_id !== undefined) {
      setIsDone(false);
      setPageLoading(true);
      try {
        const response = await axios.get(
          `${Helpers.apiUrl}chat/get/${chat_id}`,
          Helpers.authHeaders
        );
        setChat(response.data);
        setPageLoading(false);
        if (response.data.messages.length === 0) {
          Helpers.toast("success", "Let's get started with your interaction");
        } else {
          setMessages(response.data.messages);
          scrollToBottom();
        }
      } catch (error) {
        setPageLoading(false);
        Helpers.toast("error", "An error occurred while fetching the chat.");
        console.error("Error fetching chat:", error);
      }
    }
  };

  const getResponse = async () => {
    if (!TokensAvailable || !openAiApiKey || !retriever) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;  // Exit if tokens or API key is missing
    }
  
    const btnPrompt = "";
    if (btnPrompt || userInput) {
      setIsLoading(true);
      setisWriting(true);
      
      // Initial user message setup
      let msg = {
        message: btnPrompt ? btnPrompt : userInput,
        user_id: Helpers.authUser.id,
        chat_id: chat_id,
        is_bot: 0,
      };
      setMessages((old) => [...old, msg]);
  
      let finalPrompt = "";
      const logPrompt = (prompt) => {
        finalPrompt = prompt;
        return prompt;
      };
  
      const llm = new ChatOpenAI({
        openAIApiKey: openAiApiKey,
      });
  
      const standAloneTemplate = `Given a question, convert the question to a standalone question. 
        Question: {question}
        standalone question:`;
      const standAlonePrompt = PromptTemplate.fromTemplate(standAloneTemplate);
  
      const answerTemplate = `You are a helpful and enthusiastic support bot who can answer a given question based on the context provided. Try to find the answer in the context. If you really don't know the answer, say "I'm sorry, I don't know the answer to that." and direct the questioner to email help@docsphere.com. Don't try to make up an answer. Always speak as if you were chatting to a friend. Use **bold** for important points, *italic* for emphasis, and include new lines using '\\n'. 
      context: {context}
      question: {question}
      answer:
      `;
      const answerPrompt = PromptTemplate.fromTemplate(answerTemplate);
  
      const standAloneQuestionChain = standAlonePrompt
        .pipe(logPrompt)
        .pipe(llm)
        .pipe(new StringOutputParser());
  
      const retrieverChain = RunnableSequence.from([
        (prevResult) => prevResult.standalone_question,
        async (standalone_question) => {
          const docs = await retriever.getRelevantDocuments(standalone_question);
          return combineDocs(docs);  // Use the retriever to get documents
        },
      ]);
  
      const answerChain = answerPrompt
        .pipe(logPrompt)
        .pipe(llm)
        .pipe(new StringOutputParser());
  
      const simulateChain = async () => {
        try {
          const standaloneQuestionResult = await standAloneQuestionChain.invoke({
            question: userInput,
          });
          const contextResult = await retrieverChain.invoke({
            standalone_question: standaloneQuestionResult,
          });
  
          const finalPromptData = await answerPrompt.format({
            context: contextResult,
            question: userInput,
          });
  
          return finalPromptData;
        } catch (error) {
          Helpers.toast("error", "Error processing the prompt.");
          console.error("Error in simulateChain:", error);
          throw error;
        }
      };
  
      try {
        const finalPromptData = await simulateChain();
        console.log(finalPromptData);
        const data = new FormData();
      
        data.append("chatid", chat_id);
        data.append("file", chatFile);
        data.append("input", btnPrompt ? btnPrompt : userInput);
        data.append("lastprompt", finalPromptData);
      
        addMessage();
        setUserInput("");
      
        const controller = new AbortController();
        const signal = controller.signal;
      
        try {
          const response = await fetch(`${Helpers.apiUrl}bot/response`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: data,
            signal,
          });
  
          if (!response.ok) {
            const errorData = await response.json();
            Helpers.toast("error", errorData.message || "Error in response.");
            setIsLoading(false);
          } else {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let allText = ""; // To accumulate all the streamed text data
            
            // // Token usage will be retrieved from headers at the end
            // let tokenUsage = response.headers.get('X-Token-Usage');
  
            setIsLoading(false);
            setisWriting(false);
  
            async function processText({ done, value }) {
              if (done) {
                setisWriting(false);
                
                // After the stream is finished, handle the token usage
                // if (tokenUsage) {
                //   console.log('Token Usage:', tokenUsage);
                //   await updateTokens(parseInt(tokenUsage, 10));  // Update tokens with the value from headers
                // }
                return;
              }
  
              let text = decoder.decode(value);
              allText += text; // Accumulate all text
  
              let withLines = text.replace(/\\n/g, "\n");
              setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];
                updatedMessages[updatedMessages.length - 1].message += withLines;
                return updatedMessages;
              });
  
              await reader.read().then(processText);
            }
  
            // Start reading the stream
            reader.read().then(processText);
          }
          setisWriting(false);
          setIsFirstResponse(false);
        } catch (error) {
          Helpers.toast("error", "Failed to fetch the bot response.");
          console.error("Error in fetch bot response:", error);
          setIsLoading(false);
          setisWriting(false);
        }
      } catch (error) {
        Helpers.toast("error", "Failed to process the response.");
        console.error("Error in getResponse:", error);
        setIsLoading(false);
        setisWriting(false);
      }
    } else {
      Helpers.toast("error", "Can't send without input.");
    }
  };
  
  // Function to update tokens
  const updateTokens = async (totalTokensUsed) => {
    try {
      await axios.post(
        `${Helpers.apiUrl}updateTokens`,
        {
          tokens: totalTokensUsed,
        },
        Helpers.authHeaders
      );
      console.log(`Tokens updated: ${totalTokensUsed}`);
    } catch (error) {
      Helpers.toast("error", "Failed to update tokens.");
      console.error("Error updating tokens:", error);
    }
  };
  

  const addMessage = () => {
    let msg = {
      message: "",
      user_id: Helpers.authUser.id,
      chat_id: chat_id,
      is_bot: 1,
    };
    setMessages((old) => [...old, msg]);
  };

  useEffect(() => {
    if (userData) { // Check when userData is available
      if (userData.permissions === 1) {
        setNoPermission(true); // If permissions are restricted, set noPermission
      } else {
        setNoPermission(false);
      }
      setIsUserDataLoading(false); // Set loading state to false once userData is available
    }
  }, [userData]);

  useEffect(() => {
    chat_id == null || chat_id == undefined
      ? navigate("/chat/chat-interface")
      : getChat();
  }, [chat_id]);

  useEffect(() => {
    // Scroll to the bottom when the messages have been updated and loaded
    if (!pageLoading && messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, pageLoading]);

  return (
    <>
      <div className="nk-content" style={{ height: "calc(100vh - 30px)" }}>
        <div className="container-xl h-100 p-0">
          <div className="nk-content-inner h-100">
            {(pageLoading || isUserDataLoading) ? (
              <PageLoader />
            ) : (
              <div className="nk-content-body h-100  flex flex-column">
                <div
                  className="flex-grow-1 scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1]
 scrollbar-thin overflow-auto p-2 sm:p-0"
                  ref={chatContainer}
                >
                  {isFirstResponse && !isHistory ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "24px",
                        color: "#333",
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        textAlign: "center",
                        height: "100%",
                      }}
                    >
                      Your file has been uploaded successfully. You can now
                      enter your prompt.
                    </div>
                  ) : (
                    messages.map((msg, index) => {
                      const msgLength = messages.length;
                      return (
                        <div
                          key={index}
                          className={`container chat-box ${msg.is_bot == 0 ? "bg-white" : "bot-bubble"
                            }`}
                        >
                          <div className="">
                            <div className="">
                              <div className="align-center">
                                <div className={`chat-header ${msg.is_bot == 1 ? "" : "flex-row-reverse gap-2 px-2"}`}>
                                  {msg.is_bot == 0 && (
                                    <div>
                                      <img
                                        className="chat-avatar"
                                        src={Helpers.serverImage(
                                          Helpers.authUser.profile_pic
                                        )}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  {msg.is_bot == 1 && (
                                    <div className="media media-middle media-circle text-bg-primary">
                                      <img src="/app/favicon.png" alt="" />
                                    </div>
                                  )}
                                  <span className="chat-user">
                                    <strong>
                                      {msg.is_bot == 1
                                        ? "DocSphere.AI"
                                        : "You"}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <div className="chat-divider"></div>
                            </div>
                            <div className="">
                              <div className={`message ${msg.is_bot == 1 ? "" : "text-end !pb-2 pt-4"}`}>
                                <ChatGPTFormatter
                                  response={msg.message}
                                  writing={(messages.length - 1 === index && isWriting)}
                                />
                                { console.log("Writing : ",isWriting) }
                              </div>
                              {msg.is_bot == 1 && msgLength == 1 && msg.suggestions != null && (
                                <div className="pb-4 flex flex-wrap justify-center gap-2">
                                  {msg.suggestions
                                    .replace(/[\[\]']/g, '') // Remove square brackets and single quotes
                                    .split(',') // Split the string into an array based on commas
                                    .map((suggestion, index) => (
                                      <div
                                        key={index}
                                        className="card suggestion-card border border-2 transition !duration-400 border-gray-500 suggestions-card hover:!border-gray-900 cursor-pointer"
                                        onClick={() => handleSuggestionClick(suggestion.trim())} // Handle click event
                                      >
                                        <p className="text-sm suggestions-text text-black">
                                          {suggestion.trim()} {/* Display each suggestion */}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              )}
                              {msg.is_bot == 1 && (
                                <div className="d-flex justify-content-center">
                                  <button
                                    className="btn mx-1"
                                    style={{
                                      color: "#d308bd",
                                      border: "1px solid #d308bd",
                                      borderRadius: "20px",
                                    }}
                                    onClick={() =>
                                      downloadSingleMessagePDF(msg.message)
                                    }
                                  >
                                    Download PDF
                                  </button>
                                  <button
                                    className="btn"
                                    style={{
                                      color: "#d308bd",
                                      border: "1px solid #d308bd",
                                      borderRadius: "20px",
                                    }}
                                    onClick={() =>
                                      handleDownload(md.render(msg.message))
                                    }
                                  >
                                    Download DOCX
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
                <div
                  className="px-4 pt-4 special-margin rounded"
                  style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}
                >
                  <div className="">
                    <div className="d-flex ">
                      <div className="flex-grow-1">
                        <textarea
                          className="chatbot-input"
                          value={userInput}
                          onChange={(e) => setUserInput(e.target.value)}
                          placeholder={
                            isPlanActive === 'Expired'
                              ? "You cannot use the bot, please buy/renew the plan first"
                              : noPermission
                                ? "You don't have write permission."
                                : "Enter a prompt here"
                          }
                          disabled={ noPermission || isPlanActive === 'Expired' }
                        ></textarea>

                      </div>
                      <div className="ml-2">
                        <button
                          type="submit"
                          className="btn btn-md btn-primary"
                          style={{
                            backgroundColor: "#671BB3",
                            color: "#ffffff",
                            borderRadius: "20px",
                          }}
                          disabled={isLoading || isPlanActive === 'Expired' || noPermission}
                          onClick={() => {
                            setUserInput("");
                            getResponse("");
                          }}
                        >
                          <span className="mx-2">Send</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chatbot;
