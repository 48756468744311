import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideBarRedesigned from "./SideBarRedesigned";
import Helpers from "../../../Config/Helpers";
import NavBarRedesigned from "./NavBarRedesigned";
import { usePlan } from "../../../Context/PlanContext";

const ChatLayout = () => {
  const {getProfileInfo,isPlanActive} = usePlan();
  const [isMobile, setIsMobile] = useState(false);  // New state for mobile view
  const navigate = useNavigate();
  const location = useLocation();
  const handleResize = () => {
    const width = window.innerWidth;
    if (width <= 500) {
      setIsMobile(true);      // Turn off collapsed state
    } else { // Turn off collapsed state
      setIsMobile(false);    // Turn off mobile view
    }

  }
  useEffect(() => {
    getProfileInfo();
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(isPlanActive === 'Expired'){
      Helpers.toast('error','Your Plan is Expired')
    } ;
  }, [location]);
  return (
    <>
      <div className="fixed container-fluid custom-bg-root h-100 max-h-screen">
        {isMobile && (
          <NavBarRedesigned/>
        )}

        <div className="h-100 d-flex">
        {!isMobile && (
          <div className=" overflow-auto rounded-lg relative">
            <SideBarRedesigned/>
          </div>
        )}
          <div className={`col d-flex flex: 1 ${isMobile ? '!mt-16' : ''} m-2 flex-column h-100 rounded-lg  bg-white  `}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default ChatLayout;
