import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";

import PageLoader from "./Loader/PageLoader";
import { useNavigate } from "react-router-dom";
import { ChatAppContext } from "../Context/AppContext";
import Helpers from "../Config/Helpers";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { createClient } from "@supabase/supabase-js";
import { SupabaseVectorStore } from "@langchain/community/vectorstores/supabase";
import { OpenAIEmbeddings, ChatOpenAI } from "@langchain/openai";
import { HumanMessage } from "@langchain/core/messages";
import { usePlan } from "../Context/PlanContext";
import { useApi } from "../Context/ApiContext";

const defaultPrompts = {
  General: [
    "Provide a summary of the document.",
    "Highlight the key points from the text.",
    "Generate a concise overview.",
  ]
};


export default function ChatModal({ handleClose, handleShow, setShow, show }) {
  const { setNewChat } = useContext(ChatAppContext);
  const [loader, setLoader] = useState(false);
  const [fromPreviousFiles, setFromPreviousFiles] = useState(false);
  const [useCase, setUseCase] = useState("General");
  const {openAiApiKey, supabaseApiKey, supabaseUrl} = useApi();
  const [dragging, setDragging] = useState(false);
  const {TokensAvailable, userData, isPlanActive, getProfileInfo} = usePlan();
  const [isLoading, setIsLoading] = useState(false);
  const [showPreviousDocs, setShowPreviousDocs] = useState(false);
  const [previousDocs, setPreviousDocs] = useState([]);
  const [prompts, setPrompts] = useState(defaultPrompts);
  const [promptState, setPromptState] = useState(defaultPrompts.General);
  const [useCaseId, setUseCaseId] = useState(null);

  const sbUrl = supabaseUrl;
const sbApiKey = supabaseApiKey;
  console.log("API DATA ", openAiApiKey, supabaseApiKey, supabaseUrl)
  const navigate = useNavigate();
  const {
    setChatFile,
    setChatid,
    // setPromptState,
    setSelectedPrompt,
    selectedPrompt,
    setDocumentList,
  } = useContext(ChatAppContext);

  const getPreviousDocuments = async () => {
    try {
      const userString = localStorage.getItem("user");
      if (userString) {
        const user = JSON.parse(userString);
        const documents_response = await axios.get(
          `${Helpers.apiUrl}chat/all`,
          Helpers.authHeaders
        );
        if (documents_response) {
          const docs = [];
          documents_response.data.forEach((data) => {
            const filesArray = JSON.parse(data.file);
            filesArray.forEach((file) => {
              const fileNames = file.split(",").map((f) => f.trim());
              fileNames.forEach((fileName) => {
                const fileExists = docs.some(
                  (doc) => doc.file_name === fileName
                );
                if (!fileExists) {
                  docs.push({
                    file_name: fileName,
                    file_content: data.file_content,
                    chat_id: data.chat_id,
                  });
                }
              });
            });
          });
          setPreviousDocs(docs);
        } else {
          console.log("No Chats");
        }
      } else {
        console.log("No user found in localStorage");
      }
    } catch (e) {
      console.log("Error: ", e);
    }
  };

  const handlePromptStateChange = (e) => {
    setSelectedPrompt(e.target.value);
  };

  const handleUseCaseChange = (e) => {
    const selectedUseCase = e.target.value;
    console.log(selectedUseCase);
    setUseCase(selectedUseCase);
    const promptsForUseCase = prompts[selectedUseCase];
    setPromptState(promptsForUseCase);
    console.log("UseCase : ", selectedUseCase);
    console.log("Prompt State : ", prompts[selectedUseCase]);
    if (promptsForUseCase.length > 0) {
      setSelectedPrompt(promptsForUseCase[0]);
    }
  };

  useEffect(() => {
    async function fetchUseCases() {
      try {
        const response = await axios.get(`${Helpers.apiUrl}category/all`, Helpers.authHeaders);
        const useCases = response.data;

        // Prepare an object with use cases and their associated prompts as arrays
        const useCasesObject = {};
        for (const useCase of useCases) {
          useCasesObject[useCase.name] = useCase.prompts.map((prompt) => prompt.prompt); // Extracting prompts into an array
        }

        // Update the prompts state to store the fetched use cases and prompts
        setPrompts((prevPrompts) => ({ ...prevPrompts, ...useCasesObject }));

        // Optionally set the default use case if necessary
        if (useCases.length > 0) {
          const firstUseCase = useCases[0].name;
          setUseCaseId(useCasesObject[firstUseCase]); // Assuming setUseCaseId is to track the selected use case
        }
      } catch (error) {
        console.error("Error fetching use cases:", error);
      }
    }

    fetchUseCases();
  }, []);


  useEffect(() => {
    if (useCaseId) {
      async function fetchPrompts() {
        try {
          if (useCase === "General") {
            setPromptState(defaultPrompts.General);
          } else {
            const response = await axios.get(`${Helpers.apiUrl}prompt/category/${useCaseId}`, Helpers.authHeaders);
            console.log(response);
            const promptsArray = response.data;
            setPromptState(promptsArray);
          }
        } catch (error) {
          console.error("Error fetching prompts:", error);
        }
      }
      fetchPrompts();
    }
  }, [useCaseId, useCase, prompts]);

  const estimateTokensForVectorization = (text) => {
    const tokens = text.split(/\s+/).length;  // Rough estimate: one token per word
    return tokens;
  };
  
  const handleUpload = async (file) => {

    const promptid = "1";
    if (file) {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("files[]", file);
        formData.append("prompt_id", promptid);
        const response = await axios.post(
          `${Helpers.apiUrl}user/save`,
          formData,
          Helpers.authFileHeaders
        );
        const chatId = response.data.chat_id;
        setChatid(chatId);
        const textResponse = await axios.get(
          `${Helpers.apiUrl}chat/get/${chatId}`,
          Helpers.authFileHeaders
        );
        const chatData = textResponse.data.file_content;
        const splitter = new RecursiveCharacterTextSplitter({
          chunkSize: 500,
          chunkOverlap: 50,
          separators: ["\n\n", "\n", " ", ""],
        });
        const output = await splitter.createDocuments([chatData]);

        let totalTokensForVectorization = 0;
      output.forEach((chunk) => {
        totalTokensForVectorization += estimateTokensForVectorization(chunk.pageContent);
      });
      console.log("Estimated tokens for vectorization:", totalTokensForVectorization);

        const client = createClient(sbUrl, sbApiKey);
        await SupabaseVectorStore.fromDocuments(
          output,
          new OpenAIEmbeddings({
            openAIApiKey: openAiApiKey,
          }),
          {
            client,
            tableName: "documents",
          }
        );

        // Un-comment and fix the model initialization
        const model = new ChatOpenAI({
          openAIApiKey: openAiApiKey,
          model: "gpt-4o-mini",  // Ensure the model name is correct
          temperature: 0.3,
        });

        // Define the generateBriefContent function
        const generateBriefContent = async (chatData) => {
          try {
            const summary = await model.invoke([
              new HumanMessage({
                content: `Please provide a response based on the given data (May be more than 1 file), considering the selected use case and prompt:
              
              1. The use case for this response is **${useCase}**, and the specific prompt is **"${selectedPrompt}"**.
              2. Structure the response according to the selected use case and prompt. Use **bold** or *italic* formatting as needed to emphasize key points, terms, or important information, regardless of whether bullet points are used. If the use case or prompt requires it, use bullet points with labels in **bold** and explanations following.
              3. If a summary is required, provide a clear, concise paragraph that summarizes the content, without mentioning headings like "Introduction" or "Content."
              4. At the end, generate three relevant prompt suggestions in this format: "Suggestions: ['suggestion 1', 'suggestion 2', 'suggestion 3']". Ensure that **no commas** or punctuation that could split suggestions are used within each suggestion. The suggestions must be written as single strings and directly answerable based on the provided data and context. Avoid using commas within the suggestions, especially in names, to prevent breaking them into separate array items.
              
              Here is the data: ${chatData}
              
              Ensure the response uses appropriate formatting such as **bold** and *italic*, and the suggestions are formatted exactly as ['Suggestion 1', 'Suggestion 2', 'Suggestion 3'], with proper capitalization and directly related to the provided context.`,
              }),


            ]);
            // totalTokensForVectorization
            console.log("Tokens used:", summary.response_metadata.tokenUsage.totalTokens);
            const totalTokensUsed = summary.response_metadata.tokenUsage.totalTokens + totalTokensForVectorization
            await axios.post(
              `${Helpers.apiUrl}updateTokens`,
              {
                tokens: totalTokensUsed,
              },
              Helpers.authHeaders
            );
    
            await getProfileInfo();  
            console.log("Summary Data", summary.content);
            return summary.content;
          } catch (error) {
            console.error("Error generating brief content:", error);
            throw error;
          }
        };

        // Generate brief content and save it to the database
        const briefContent = await generateBriefContent(chatData);

        // Find the index of "Suggestions:" and split the content
        const suggestionsIndex = briefContent.indexOf("Suggestions:");
        const responseMessage = briefContent.substring(0, suggestionsIndex).trim();
        let suggestions = briefContent.substring(suggestionsIndex + "Suggestions:".length).trim();
        if (suggestions.startsWith('-')) {
          suggestions = suggestions.slice(1).trim(); // Remove the leading `-`
        }
        // Now you have `responseMessage` and `suggestions`
        console.log("Response Message:", responseMessage);
        console.log("Suggestions:", suggestions);

        const data = {
          input: responseMessage,
          suggestions: suggestions,
          user_id: Helpers.authUser.id,
          chatid: chatId,
        };
        await axios.post(`${Helpers.apiUrl}bot/firstmessage`, data, Helpers.authHeaders);

        const newChat = {
          chat_detail: chatData.replace(/\n/g, " ").slice(0, 40),
          chat_id: chatId,
          file: [file.name],
        };
        setNewChat(newChat);
        Helpers.toast("success", "File uploaded successfully");
        console.log("Chat ID", chatId);
        return chatId;
      } catch (error) {
        if (error.response) {
          Helpers.toast("error", error.response.data.message);
        } else {
          Helpers.toast("error", "Unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      Helpers.toast("error", "Can't send without file");
    }
  };


  const handleUploadButton = async (e) => {
    if (userData.permissions == 1) {
      Helpers.toast("error", "You Only Have Read Permissions");
      return;  // This will exit the function immediately
    }
    if (!TokensAvailable) {
      Helpers.toast("error", "Your Tokens Are Almost Finished");
      return;  // This will exit the function immediately
    }
    if(isPlanActive == "Expired"){
      Helpers.toast("error", "Your Plan Is Expired");
      return;  // This will exit the function immediately
    }
    const file = e.target.files[0];
    setDocumentList((prevList) => [...prevList, file]);

    setChatFile(file);

    if (!file) return;

    setLoader(true);

    try {
      const chat_id = await handleUpload(file);
      setLoader(false);
      navigate(`/chat/chatbot/${chat_id}`);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };



  const handlePreviousDocSelect = async (doc) => {
    const request_params = {
      file_name: doc.file_name,
      file_content: doc.file_content,
      prompt_id: "1",
    };
    setLoader(true);
    try {
      const respones = await axios.post(
        `${Helpers.apiUrl}user/chatSession/`,
        request_params,
        Helpers.authHeaders
      );
      console.log("Response: ", respones.data);
      if (respones.status === 200) {
        console.log("Response: ", respones.data.chat_id);
        const newChat = {
          chat_detail: request_params.file_content
            .replace(/\n/g, " ")
            .slice(15, 40),
          chat_id: respones.data.chat_id,
          file: [doc.file_name],
        };

        setNewChat(newChat);
        setLoader(false);
        navigate(`/chat/chatbot/${respones.data.chat_id}`);
      }
    } catch (error) {
      console.error("Error selecting previous document:", error);
    }
  };
  const handleChatWithPreviousDocs = () => {
    setShowPreviousDocs(!showPreviousDocs);
  };
  const handleDragOver = (e) => {
    e.preventDefault(); // Prevent default behavior
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      setChatFile(file);
      handleUploadButton({ target: { files: [file] } });
    }
  };
  useEffect(() => {
    console.log("Selected Prompt: ", selectedPrompt); // Log the selected prompt whenever it changes
  }, [selectedPrompt]); // This useEffect runs when selectedPrompt changes
  return (
    <>
      <Modal show={show} onHide={handleClose} style={{ padding: "20px" }}>
        {loader ? (
          <PageLoader />
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Let's Decode this Document!: ✨</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="w-100 d-flex flex-column flex-sm-row gap-4">
                <div className="d-flex w-100 w-sm-50 flex-sm-fill flex-column gap-3">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex gap-1 align-items-center">
                      <label className="fw-medium">Select Use Case</label>
                    </div>
                    <option value="General">General</option>
                    <Form.Select value={useCase} onChange={handleUseCaseChange}>
                      {Object.keys(prompts).map((useCaseName, index) => (
                        <option key={index} value={useCaseName}>
                          {useCaseName}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex gap-1 align-items-center">
                      <label className="fw-medium">Choose Prompt</label>
                    </div>
                    <Form.Select onChange={handlePromptStateChange}>
                      {prompts[useCase].map((prompt, index) => (
                        <option key={index} value={prompt}>
                          {prompt}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      onChange={() => setFromPreviousFiles(!fromPreviousFiles)}
                      type="checkbox"
                      checked={fromPreviousFiles}
                      className="sr-only peer"
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue dark:peer-focus:ring-offset-blue-950 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                    <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                      Chat from previous documents
                    </span>
                  </label>
                </div>
                {fromPreviousFiles ? (
                  <div className="d-flex w-100 w-sm-50 flex-sm-fill flex-column gap-3">
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex gap-1 align-items-center">
                        <label className="fw-medium">Select Files</label>
                      </div>
                      <div>
                        <ul
                          className="list-group"
                          style={{ maxHeight: "240px", overflowY: "auto" }}
                        >
                          {previousDocs.map((doc, index) => (
                            <li
                              key={index}
                              className="list-group-item list-group-item-action"
                              onClick={() => handlePreviousDocSelect(doc)}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="truncate w-full text-gray-900 dark:text-gray-300">
                                {doc.file_name}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`d-flex w-100 w-sm-50 flex-sm-fill flex-column gap-3 ${dragging ? "border border-primary bg-light" : ""
                      }`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                    style={{ height: "230px", border: "2px dashed lightgrey", position: "relative" }}
                  >
                    <label
                      className="d-flex justify-content-center align-items-center w-100 h-100"
                      htmlFor="abc"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="file"
                        id="abc"
                        onChange={handleUploadButton}
                        style={{ opacity: 0, position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
                      />
                      <div>
                        <p className="text-center">
                          Drag and drop your files here or click to upload
                        </p>
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );

}
