const ChatGPTFormatter = ({ response, writing }) => {
  // Function to format response
  const formatResponse = (text) => {
    const boldText = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    const italicText = boldText.replace(/\*(.*?)\*/g, "<em>$1</em>");
    return italicText.replace(/\n/g, "<br/>");
  };

  return (
    <div
      className={`chat-gpt-formatter ${writing ? 'writing' : ''}`}
      style={{
        display: 'inline-block',
        position: 'relative',
      }}
    >
      <span 
        dangerouslySetInnerHTML={{ __html: formatResponse(response) }}
        style={{
          marginRight: writing ? 5 : 0, // add some space for the cursor
        }}
      />
      {writing && (
        <span
          className="cursor"
          style={{
            display: 'inline-block',
            position: 'relative',
            right: 0,
            top: 0,
          }}
        >
          |
        </span>
      )}
    </div>
  );
};

export default ChatGPTFormatter;