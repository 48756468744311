import { Link, useNavigate } from "react-router-dom";
import useTitle from "../../Hooks/useTitle";
import { useEffect, useState } from "react";
import axios from "axios";
import Helpers from "../../Config/Helpers";

const Register = () => {
  useTitle("Register");
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({}); // Clear previous errors
    try {
      const response = await axios.post(
        `${Helpers.apiUrl}auth/register`,
        user,
        Helpers.authHeaders
      );
      Helpers.toast("success", response.data.message);
      localStorage.setItem("user_id", response.data.user_id);
      navigate("/verify-email");
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        setErrors(error.response.data.errors || {});
        Helpers.toast(
          "error",
          error.response.data.message || "Registration failed"
        );
      } else if (error.request) {
        // Request was made but no response received
        Helpers.toast(
          "error",
          "No response from server. Please try again later."
        );
      } else {
        // Something else happened while setting up the request
        Helpers.toast(
          "error",
          "An unexpected error occurred. Please try again."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="login-page full-height">
      <div
        className="login-page__shape-1"
        style={{
          backgroundImage: "url(assets/images/shapes/login-page-shape-1.png)",
        }}
      ></div>
      <div className="login-page__shape-2"></div>
      <div className="container">
        <div className="login-page__inner glass">
          <div className="login-page__top">
            <div className="login-page__logo">
              <a href="index.html">
                <img src="app/logo-sq-duo.png" className="w100 mx-auto" alt="" />
              </a>
            </div>
          </div>
          <form
            onSubmit={handleRegistration}
            className="register-one__form"
            autoComplete="off"
          >
            <div className="row">
              <div className="col-md-12">
                <label className="form-label" htmlFor="emailorusername">
                  Name
                </label>
                <div className="register-one__form__email">
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    value={user.name}
                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                    placeholder="Enter Full Name"
                  />
                  <small className="text-danger">
                    {errors.name ? errors.name[0] : ""}
                  </small>
                </div>
              </div>
              <div className="col-md-12">
                <div className="register-one__form__email">
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    value={user.email}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    placeholder="Enter Email"
                  />
                  <small className="text-danger">
                    {errors.email ? errors.email[0] : ""}
                  </small>
                </div>
              </div>
              <div className="col-md-12">
                <div className="register-one_form_password position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control form-control-lg"
                    value={user.password}
                    onChange={(e) =>
                      setUser({ ...user, password: e.target.value })
                    }
                    placeholder="Enter Password"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="position-absolute"
                    style={{
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  >
                    <i
                      className={
                        showPassword ? "fas fa-eye-slash" : "fas fa-eye"
                      }
                    ></i>
                  </span>
                  <small className="text-danger">
                    {errors.password ? errors.password[0] : ""}
                  </small>
                </div>
              </div>
              <div className="col-md-12">
                <div className="register-one_form_password position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control form-control-lg"
                    value={user.password_confirmation}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        password_confirmation: e.target.value,
                      })
                    }
                    placeholder="Confirm Password"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="position-absolute"
                    style={{
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  ></span>
                  <small className="text-danger">
                    {errors.password_confirmation
                      ? errors.password_confirmation[0]
                      : ""}
                  </small>
                </div>
              </div>
              <div className="col-md-12">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="thm-btn register-one__btn"
                >
                  {isLoading ? "Creating account..." : "Create My Free Account"}
                </button>
              </div>
            </div>
          </form>
          <p className="register-one__tagline">
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Register;
