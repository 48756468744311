import React, { createContext, useContext, useState, useEffect } from 'react';
import Helpers from '../Config/Helpers';
import axios from 'axios';

// Create the ApiContext
const ApiContext = createContext();

// ApiProvider component to wrap the application
export const ApiProvider = ({ children }) => {
    const [openAiApiKey, setOpenAiApiKey] = useState("");
    const [supabaseApiKey, setSupabaseApiKey] = useState("");
    const [supabaseUrl, setSupabaseUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    // Function to fetch the API keys from the backend
    const fetchApiKeys = async () => {
        setIsLoading(true);
        try {
            // Fetch API keys from the settings endpoint
            const response = await axios.get(`${Helpers.apiUrl}settings`, Helpers.authHeaders);

            // Set API keys in state
            setOpenAiApiKey(response.data.openaikey || "");
            setSupabaseApiKey(response.data.supabasekey || "");
            setSupabaseUrl(response.data.supabaseurl || "");
        } catch (error) {
            console.error("Error fetching API keys: ", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetch API keys when the component mounts
    useEffect(() => {
        fetchApiKeys();
    }, []);

    // Provide the API keys and fetch function throughout the app
    return (
        <ApiContext.Provider value={{ openAiApiKey, supabaseApiKey, supabaseUrl, isLoading, fetchApiKeys }}>
            {children}
        </ApiContext.Provider>
    );
};

// Hook to use ApiContext
export const useApi = () => useContext(ApiContext);
