import axios from "axios";
import { useEffect, useState } from "react";
import logo from "../../logo-duo.png";
import PageLoader from "../../Components/Loader/PageLoader";
import useTitle from "../../Hooks/useTitle";
import Helpers from "../../Config/Helpers";
import { usePlan } from "../../Context/PlanContext";

const UserProfile = () => {
  useTitle("User Profile");

  const defaultPass = {
    password: "",
    password_confirmation: "",
  };

  const defaultMember = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    is_org_owner: false,
    is_active: true,
    org_id: Helpers.authUser.org_id,
    permissions: 1,
  };

  const [user, setUser] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const { userData, latestTransaction } = usePlan();

  const [isLoading, setIsLoading] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [password, setPassword] = useState(defaultPass);
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState("profile");

  // Organization Members States
  const [members, setMembers] = useState([]);
  const [memberData, setMemberData] = useState(defaultMember);
  const [isEditing, setIsEditing] = useState(false);
  const [showAddMember, setShowAddMember] = useState(false);

  // Transaction States
  const [transactions, setTransactions] = useState([]);

  const getProfileInfo = () => {
    setPageLoading(true);
    axios
      .get(`${Helpers.apiUrl}user/info`, Helpers.authHeaders)
      .then((response) => {
        Helpers.setItem("user", response.data.user, true);
        setUser(response.data.user);
        setPageLoading(false);
      });
  };

  const getUserTransactions = () => {
    axios
      .get(`${Helpers.apiUrl}transactions/user/${Helpers.authUserId}`, Helpers.authHeaders)
      .then((response) => {
        console.log(response.data)
        setTransactions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching transactions:", error);
      });
  };

  const unsubscribeTransaction = (id) => {
    axios
      .post(`${Helpers.apiUrl}transactions/unsubscribe/${id}`, {}, Helpers.authHeaders)
      .then((response) => {
        Helpers.toast("success", response.data.message);
        getUserTransactions();
      })
      .catch((error) => {
        console.error("Error unsubscribing transaction:", error);
      });
  };

  const updateProfilePic = (e) => {
    setIsLoading(true);
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("profile_pic", file);
    axios
      .post(`${Helpers.apiUrl}user/update-picture`, formData, Helpers.authFileHeaders)
      .then((response) => {
        setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        Helpers.toast("error", error.response.data.message);
        setIsLoading(false);
      });
  };

  const updatePasswordHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axios
      .post(`${Helpers.apiUrl}user/update-password`, password, Helpers.authHeaders)
      .then((response) => {
        Helpers.toast("success", response.data.message);
        setUpdatePassword(false);
        setPassword(defaultPass);
        setIsLoading(false);
      })
      .catch((error) => {
        Helpers.toast("error", error.response.data.message);
        setErrors(error.response.data.errors || {});
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProfileInfo();
  }, []);

  // Fetch organization members
  const getOrganizationMembers = () => {
    axios
      .get(`${Helpers.apiUrl}members/all-members`, Helpers.authHeaders)
      .then((response) => {
        setMembers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching members:", error);
      });
  };

  // Add or Edit Member
  const saveMember = () => {
    if (memberData.password && memberData.password !== memberData.password_confirmation) {
      setErrors({ password_confirmation: "Passwords do not match" });
      return;
    }
    setIsLoading(true);
    axios
      .post(
        `${Helpers.apiUrl}members/save-member`,
        { ...memberData, org_id: user.org_id },
        Helpers.authHeaders
      )
      .then((response) => {
        setIsEditing(false);
        setShowAddMember(false);
        setIsLoading(false);
        getOrganizationMembers();
        setMemberData(defaultMember); // Reset the form
        Helpers.toast("success", response.data.message);
      })
      .catch((error) => {
        console.error("Error saving member:", error);
        setErrors(error.response.data.errors || {});
        setIsLoading(false);
      });
  };

  // Edit Member
  const editMember = (member) => {
    setMemberData({
      name: member.name,
      email: member.email,
      is_org_owner: member.is_org_owner,
      is_active: member.is_active,
      id: member.id,
      password: "",
      password_confirmation: "",
      permissions: member.permissions,
    });
    setShowAddMember(true);
    setIsEditing(true);
  };

  // Delete Member
  const deleteMember = (id) => {
    axios
      .delete(`${Helpers.apiUrl}members/delete/${id}`, Helpers.authHeaders)
      .then((response) => {
        Helpers.toast("success", response.data.message);
        getOrganizationMembers();
      })
      .catch((error) => {
        console.error("Error deleting member:", error);
      });
  };

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <div className="overflow-y-auto scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1] scrollbar-thin px-2">
      <div className="flex justify-center items-center min-h-screen py-10">
        <div className="w-full max-w-5xl bg-white rounded-lg shadow-md p-6">
          {/* Header with Logo */}
          <div className="flex justify-between items-center w-full py-4 px-8 border-b border-gray-300">
            <div className="flex items-center gap-3">
              <a href="/">
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "120px", height: "40px", padding: "5px", borderRadius: "4px" }}
                />
              </a>
              <p className="badge text-custom px-2 py-1 text-lg">User Profile</p>
            </div>
          </div>

          {/* Tabs Navigation */}
          <div className="mt-6 text-center">
            <div className="inline-flex space-x-4 border-b">
              <button
                className={`pb-2 ${activeTab === "profile" ? "border-b-2 border-blue-500" : ""}`}
                onClick={() => setActiveTab("profile")}
              >
                Profile Info
              </button>

              {/* Conditionally show Organization Members tab if user is org owner */}
              {user.is_org_owner && user.org_id != null ? (
                <button
                  className={`pb-2 ${activeTab === "organization" ? "border-b-2 border-blue-500" : ""}`}
                  onClick={() => {
                    setActiveTab("organization");
                    getOrganizationMembers(); // Load members when tab is clicked
                  }}
                >
                  Organization Members
                </button>
              ) : null }

              {/* Transactions Tab */}
              {((user.is_org_owner && user.org_id != null) || (!user.is_org_owner && user.org_id == null)) ? (
              <button
                className={`pb-2 ${activeTab === "transactions" ? "border-b-2 border-blue-500" : ""}`}
                onClick={() => {
                  setActiveTab("transactions");
                  getUserTransactions(); // Fetch user transactions when tab is clicked
                }}
              >
                Transactions
              </button>
              ) : null }
            </div>
          </div>

          {/* Tab Content */}
          <div className="mt-4 flex justify-center">
            {activeTab === "profile" && (
              <div className="w-full max-w-5xl mx-auto flex flex-col md:flex-row justify-between bg-white rounded-lg shadow-md p-6 gap-4">
                {/* Profile Info */}
                <div className="flex mx-auto">
                  {!updatePassword ? (
                    <div>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-4">
                          <img
                            className="w-24 h-24 rounded-full object-cover"
                            src={Helpers.serverImage(user.profile_pic)}
                            alt="Profile"
                          />
                          <div>
                            <p className="text-xl font-semibold">{user.name}</p>
                            <p className="text-gray-600">{user.email}</p>
                            <label
                              htmlFor="profile_picture_select"
                              className="btn btn-primary btn-sm mt-2 cursor-pointer"
                            >
                              Update Profile Picture
                            </label>
                            <input
                              id="profile_picture_select"
                              type="file"
                              className="hidden"
                              onChange={updateProfilePic}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Profile Details */}
                      <div className="mt-6">
                        <table className="table-auto w-full text-left">
                          <tbody>
                            <tr>
                              <td className="py-2 text-gray-600">Full Name</td>
                              <td className="py-2">{user.name}</td>
                            </tr>
                            <tr>
                              <td className="py-2 text-gray-600">Email</td>
                              <td className="py-2">{user.email}</td>
                            </tr>
                            <tr>
                              <td className="py-2 text-gray-600">Password</td>
                              <td className="py-2">
                                <button
                                  className="text-blue-500 hover:underline"
                                  onClick={() => setUpdatePassword(true)}
                                >
                                  Change Password
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-6">
                      <div className="border-t border-gray-300 pt-6">
                        <h3 className="text-lg font-semibold mb-4">Update Your Password</h3>
                        <form onSubmit={updatePasswordHandler}>
                          <div className="mb-4">
                            <label className="block text-gray-700">New Password</label>
                            <input
                              type="password"
                              value={password.password}
                              onChange={(e) =>
                                setPassword({ ...password, password: e.target.value })
                              }
                              className="w-full p-2 border border-gray-300 rounded-md"
                              placeholder="Enter New Password"
                            />
                            {errors.password && (
                              <p className="text-red-500 text-sm mt-1">{errors.password[0]}</p>
                            )}
                          </div>

                          <div className="mb-4">
                            <label className="block text-gray-700">Confirm Password</label>
                            <input
                              type="password"
                              value={password.password_confirmation}
                              onChange={(e) =>
                                setPassword({ ...password, password_confirmation: e.target.value })
                              }
                              className="w-full p-2 border border-gray-300 rounded-md"
                              placeholder="Confirm Your Password"
                            />
                            {errors.password_confirmation && (
                              <p className="text-red-500 text-sm mt-1">
                                {errors.password_confirmation[0]}
                              </p>
                            )}
                          </div>

                          <div className="flex items-center justify-between">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isLoading}
                            >
                              {isLoading ? "Saving..." : "Save New Password"}
                            </button>
                            <button
                              className="btn btn-outline-danger"
                              onClick={() => setUpdatePassword(false)}
                              disabled={isLoading}
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {activeTab === "organization" && (
              <div className="w-full max-w-5xl mx-auto overflow-auto scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1] scrollbar-thin flex flex-col justify-between bg-white rounded-lg shadow-md p-6">
                {/* <h2 className="text-xl font-semibold mb-4">Organization Members</h2> */}
                <div className="flex justify-between items-center  my-auto">
                  <h2 className="text-xl font-semibold">Organization Members</h2>
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowAddMember(true)}
                  >
                    Add New Member
                  </button>
                </div>

                {!showAddMember && (
                  <table className="table-auto w-full text-left mt-4">
                    <thead>
                      <tr>
                        <th className="px-4 py-2">Name</th>
                        <th className="px-4 py-2">Email</th>
                        <th className="px-4 py-2">Role</th>
                        <th className="px-4 py-2">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {members.length === 0 ? (
                        <tr>
                          <td colSpan={4} className="text-center py-4">
                            No members found.
                          </td>
                        </tr>
                      ) : (
                        members.map((member) => (
                          <tr key={member.id}>
                            <td className="px-4 py-2">{member.name}</td>
                            <td className="px-4 py-2">{member.email}</td>
                            <td className="px-4 py-2">
                              {member.is_org_owner ? "Owner" : "Member"}
                            </td>
                            <td className="px-4 py-2">
                              <button
                                className="text-blue-500 hover:underline mr-4"
                                onClick={() => editMember(member)}
                              >
                                Edit
                              </button>
                              <button
                                className="text-red-500 hover:underline"
                                onClick={() => deleteMember(member.id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                )}

                {showAddMember && (
                  <div className="mt-6">
                    <h3 className="text-lg font-semibold mb-4">
                      {isEditing ? "Edit Member" : "Add New Member"}
                    </h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label className="block text-gray-700">Name</label>
                        <input
                          type="text"
                          className="w-full p-2 border border-gray-300 rounded-md"
                          value={memberData.name}
                          onChange={(e) => setMemberData({ ...memberData, name: e.target.value })}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700">Email</label>
                        <input
                          type="email"
                          className="w-full p-2 border border-gray-300 rounded-md"
                          value={memberData.email}
                          onChange={(e) => setMemberData({ ...memberData, email: e.target.value })}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700">Password</label>
                        <input
                          type="password"
                          className="w-full p-2 border border-gray-300 rounded-md"
                          value={memberData.password}
                          onChange={(e) => setMemberData({ ...memberData, password: e.target.value })}
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700">Confirm Password</label>
                        <input
                          type="password"
                          className="w-full p-2 border border-gray-300 rounded-md"
                          value={memberData.password_confirmation}
                          onChange={(e) =>
                            setMemberData({ ...memberData, password_confirmation: e.target.value })
                          }
                        />
                      </div>
                      <div>
                        <label className="block text-gray-700">Role</label>
                        <select
                          className="w-full p-2 border border-gray-300 rounded-md"
                          value={memberData.is_org_owner}
                          onChange={(e) =>
                            setMemberData({ ...memberData, is_org_owner: e.target.value })
                          }
                        >
                          <option value={false}>Member</option>
                          <option value={true}>Owner</option>
                        </select>
                      </div>
                      <div>
                        <label className="block text-gray-700">Permissions</label>
                        <select
                          className="w-full p-2 border border-gray-300 rounded-md"
                          value={memberData.permissions}
                          onChange={(e) =>
                            setMemberData({ ...memberData, permissions: e.target.value })
                          }
                        >
                          <option value={1}>Read</option>
                          <option value={2}>Read/Write</option>
                        </select>
                      </div>
                    </div>

                    <div className="flex justify-end gap-4 mt-4">
                      <button
                        className="btn btn-primary"
                        onClick={saveMember}
                        disabled={isLoading}
                      >
                        {isLoading ? "Saving..." : isEditing ? "Save Changes" : "Add Member"}
                      </button>
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => {
                          setShowAddMember(false);
                          setMemberData(defaultMember);
                          setIsEditing(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {activeTab === "transactions" && (
              <div className="w-full max-w-5xl mx-auto overflow-auto scrollbar-thumb-[#160647] scrollbar-track-[#ECEFF1] scrollbar-thin flex flex-col justify-between bg-white rounded-lg shadow-md p-6">
                <h2 className="text-2xl font-bold mb-4">Your Transactions</h2>

                {transactions.length === 0 ? (
                  <p className="text-center">No transactions found.</p>
                ) : (
                  <table className="table-auto w-full text-left">
                    <thead>
                      <tr>
                        <th className="px-4 py-2">Transaction ID</th>
                        <th className="px-4 py-2">Package Type</th>
                        <th className="px-4 py-2">Purchase Date</th>
                        <th className="px-4 py-2">Expiry Date</th>
                        <th className="px-4 py-2">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td className="px-4 py-2">{transaction.id}</td>
                          <td className="px-4 py-2">{transaction.package_type}</td>
                          <td className="px-4 py-2">{transaction.purchase_date}</td>
                          <td className="px-4 py-2">{transaction.expiry_date}</td>
                          <td className="px-4 py-2">
                            <button
                              className="text-red-500 hover:underline"
                              onClick={() => unsubscribeTransaction(transaction.id)}
                            >
                              Unsubscribe
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
