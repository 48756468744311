import React, { createContext, useContext, useState, useEffect } from 'react';
import Helpers from '../Config/Helpers';
import axios from 'axios';

const PlanContext = createContext();

export const PlanProvider = ({ children }) => {
    const [isPlanActive, setIsPlanActive] = useState("");
    const [userData, setUserData] = useState(null);
    const [userTransactions, setuserTransactions] = useState([]);
    const [Transaction, setTransaction] = useState(null);
    const [TokensAvailable, setTokensAvailable] = useState(false);
    const getProfileInfo = async () => {
        try {
            const response = await axios.get(`${Helpers.apiUrl}user/info`, Helpers.authHeaders);
            Helpers.setItem('user', response.data.user, true);

            // Set user data and transactions
            setUserData(response.data.user);
            const userAllTransactions = response.data.user.transactions;
            setuserTransactions(userAllTransactions);

            const latestTransaction = response.data.latestTransaction;
            const totalTokens = response.data.total_used_tokens;
            setTransaction(latestTransaction); 

            // Check if the plan is active or expired
            const user = response.data.user;
            if ((user && latestTransaction) || (user.org_id != null && latestTransaction)) {
                const expiryDate = new Date(latestTransaction.expiry_date);
                const currentDate = new Date();
                if (expiryDate < currentDate) {
                    setIsPlanActive("Expired");
                } else {
                    setIsPlanActive("Active");
                }
            }

            if(user.org_id == null && user.is_org_owner == 0){
                if ((user.no_tokens - user.used_tokens) < 350 ){
                    setTokensAvailable(false)
                } else{
                    setTokensAvailable(true)
                }
            } else if(user.org_id != null){
                if ((user.organization.no_tokens - totalTokens) < 350 ){
                    setTokensAvailable(false)
                } else{
                    setTokensAvailable(true)
                }
            }

        } catch (error) {
            console.error("Error fetching user info: ", error);
        }
    };

    useEffect(() => {
        getProfileInfo();
    }, []);

    return (
        <PlanContext.Provider value={{ userTransactions, TokensAvailable, Transaction, getProfileInfo, isPlanActive, userData }}>
            {children}
        </PlanContext.Provider>
    );
};

export const usePlan = () => useContext(PlanContext);
